import React from "react"
import style from "./heading.module.css"

function Heading({ children }) {
    return (
        <div>
            <h1 class={style.underline} >
                {children}
            </h1>
        </div>
    )
}

function Heading2({ children }) {
    return (
        <div>
            <h2 class={style.underline} >
                {children}
            </h2>
        </div>
    )
}

function Heading4({ children }) {
    return (
        <div>
            <h4 class={style.plain} >
                {children}
            </h4>
        </div>
    )
}

function SlashHeading({ children }) {
    return (
        <div>
            <h3 class={style.slash}>
                /{children}
            </h3>
        </div>
    )
}

export {Heading, Heading2, Heading4, SlashHeading}
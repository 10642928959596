import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import DarkModeToggle from "./dark-mode-toggle";

const Footer = props => (
  <footer>
    <a
      href="https://github.com/dasconnor"
      css={css`
        float: right;
        padding-top: 20px;
        margin-right: 0px;
        text-decoration: none;
        color: var(--link-header);
      `}
    >
      Github
    </a>
    <a
      href="http://instagram.com/das_connor"
      css={css`
        float: right;
        padding-top: 20px;
        margin-right: 10px;
        text-decoration: none;
        color: var(--link-header);
      `}
    >
      Instagram
    </a>
    <a
      href="http://twitter.com/das_connor"
      css={css`
        float: right;
        padding-top: 20px;
        margin-right: 10px;
        text-decoration: none;
        color: var(--link-header);
      `}
    >
      Twitter
    </a>
    <p
      css={css`
      float: right;
      padding-top: 1px;
      clear: both;

      text-decoration: none;
      color: var(--link-header);
    `}
    >
      &#169; 2020 Connor Daly
    </p>
  </footer>
)

export default function Layout({ children }) {
    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      )

    return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 800px;
        padding: 32px;
        padding-top: 16px;
      `}
    >
      <div css={css`
        border-bottom: 2px solid #111111;
        margin-bottom: 30px;
        padding-left: 10px;
        padding-bottom: 10px;
      `}>
        <Link to={`/`}>
          <h1
            css={css`
              display: inline-block;
              font-style: normal;
              text-decoration: none;
              color: var(--text);
              padding-bottom: 0px;
              margin-bottom: 0px;
            `}
          >
            {data.site.siteMetadata.title}
          </h1>
        </Link>
        <nav>
          <div className="desktop-only">
            <DarkModeToggle />
          </div>
          <Link
            to={`/blog/`}
            css={css`
              margin-right: 10px;
              text-decoration: none;
              color: var(--link-header);
            `}
          >
            Writing
          </Link>
          <Link
            to={`/recommendations/`}
            css={css`
              margin-right: 10px;
              text-decoration: none;
              color: var(--link-header);
            `}
          >
            Recommendations
          </Link>
          <Link
            to={`/about/`}
            css={css`
              margin-right: 10px;
              text-decoration: none;
              color: var(--link-header);
            `}
          >
            About
          </Link>
          
        </nav>
        <div className="mobile-only">
            <DarkModeToggle />
          </div>
      </div>
      {children}
      <Footer />
    </div>
  )
}
import React from "react"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import { Heading } from "../components/heading"
import WritingSummaries from "../components/writing-summaries"
import SEO from "../components/seo"

export default function Writing({ data }) {
  return (
    <Layout>
      <SEO
        title="Writing"
        description="Decent Alternative Systems Blog"
      />
      <PageTitle>Writing</PageTitle>
        <div>
            <Heading>Life and Provocations</Heading>
            <WritingSummaries />
        </div>
            
    </Layout>
  )
}

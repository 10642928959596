import React from "react"
import { css } from "@emotion/core"

export default function PageTitle({ children }) {
    return (
        <div css={css`
        border-bottom: 2px solid #111111;
        margin-bottom: 30px;
        padding-bottom: 10px;

      `}>
        <center>
          <h1>
            {children}
          </h1>
        </center>
      </div>
  )
}